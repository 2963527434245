<template>
    <span class="size-2 text-[#8b8b8b] text-xs lg:text-sm">{{ preText }} erklären Sie sich mit unseren
        <span class="cursor-pointer underline" @click="modal.TermsConditionsInfo = true">AGB</span> einverstanden.
        Sie haben die <span class="cursor-pointer underline" @click="modal.RecallInfo = true">Widerrufsbelehrung</span>
        und die <span class="cursor-pointer underline" @click="modal.DataSecurityInfo = true">Datenschutzbestimmungen</span>
        {{ postText }}.
    </span>
    <Teleport to="body">
        <template v-if="modal.DataSecurityInfo">
        <div class="modal-overlay" @click="modal.DataSecurityInfo = false">
            <div class="modal" @click.stop>
                <div class="close" @click="modal.DataSecurityInfo = false">
                    <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
                </div>
                <LazyContentStaticDataSecurity />
            </div>
        </div>
    </template>
    <template v-if="modal.TermsConditionsInfo">
        <div class="modal-overlay" @click="modal.TermsConditionsInfo = false">
            <div class="modal" @click.stop>
                <div class="close" @click="modal.TermsConditionsInfo = false">
                    <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
                </div>
                <ContentStaticAGB />
            </div>
        </div>
    </template>
    <template v-if="modal.RecallInfo">
        <div class="modal-overlay" @click="modal.RecallInfo = false">
            <div class="modal" @click.stop>
                <div class="close" @click="modal.RecallInfo = false">
                    <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
                </div>
                <ContentStaticRecallInformation />
            </div>
        </div>
    </template>
    </Teleport>
    
</template>
<script setup>
    const props = defineProps({
        preText: {
            type: String,
            default: "Mit dem Fortfahren"
        },
        postText: {
            type: String,
            default: "sowie die Nutzung der Daten durch den ausführenden Fachmarkt zur Kenntnis genommen"
        }
    })

    var modal = ref({
        DataSecurityInfo: false,
        TermsConditionsInfo: false,
        RecallInfo: false,
    });
</script>
