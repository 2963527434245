<template>
    <div class="grow">
        Ich habe die <a :href="warranty?.listOfFiles?.list[0]?.fileUrl" 
            class="text-primary" target="_blank" rel="noopener norefferer">Wertgarantie Bedingungen</a>
        für den WERTGARANTIE Geräteschutz, die Widerrufsbelehrung, das Produktinformationsblatt
        sowie die Hinweise zum Datenschutz zur Kenntnis genommen und akzeptiere diese.
    </div>
</template>
<script setup>
    import { getExpArticle } from "~/composables/ExpApi/expArticle";
    const props = defineProps({
        item: {
            type: Object,
        },
        warrantyId: {
            type: String,
        }
    })
    const { data: usedArticle } = await getExpArticle(props.item?.articleId);
    const {data: warranties} = await useFetch('/api/warranty', {
        query: {
            webcode: usedArticle?.value.webcode,
            price: props.item?.price?.gross,
            productType: usedArticle?.value?.productType
        }
    })
    let warranty = ref()
    for(let i = 0; i < warranties?.value?.warrantyList?.length; i++) {
        if(warranties?.value?.warrantyList[i]?.typeName == props.warrantyId) {
            warranty.value = warranties?.value?.warrantyList[i]
            break
        }
    }
</script>
